$darkblue: #0457bc;
$black: #000;
$white: #fff;

%font-std {
	font-family: Bogle, sans-serif;
	font-size: 16px;
	color: $black;
}

%font-18 {
	font-family: Bogle, sans-serif;
	font-size: 18px;
	color: $black;
}

%font-18-b {
	font-family: Bogle, sans-serif;
	font-size: 18px;
	font-weight: bold;
	color: $black;
}

%fullwidth {
	width: 100%;
}

%flex-column {
	display: flex;
	flex-direction: column;
}

%flex-row {
	display: flex;
	flex-direction: row;
}

.dialogSize {
	width: 537px;
	border-radius: 8px;
	@media (min-width: 0px) and (max-width: 640px) {
		width: 100%;
	}
}

.container {
	display: flex;
	flex-direction: column;
	padding: 5px 15px 10px 10px;
}

.closePanel {
	display: flex;
	justify-content: space-between;
	padding: 10px 0 10px 10px;
	margin-bottom: 7px;
	border-bottom: solid 2px #ededed;
	@media (min-width: 0px) and (max-width: 640px) {
		margin-bottom: 0;
	}
}

.closeIcon {
	width: 22;
	cursor: pointer;
	font-size: 35px;
	color: #c9c9c9;
}

.dialogTitle {
	font-family: Bogle, sans-serif;
	font-size: 24px;
	font-weight: bold;
	color: #041c2c;
	display: flex;
	flex-direction: row;
	justify-content: center;
	@media (min-width: 0px) and (max-width: 640px) {
		font-size: 18;
	}
	@media (min-width: 641px) and (max-width: 1024px) {
		font-size: 23;
	}
}

.mainContent {
	@extend %flex-column;
	@extend %font-std;
	padding:0 3px 0 10px;
	justify-content: space-between;
	@media (min-width: 0) and (max-width: 1024px) {
		flex-direction: column;
	}
}

.leftSection {
	@extend %flex-column;
  width: 50%;
  margin-right: 10px;
	@media (min-width: 0) and (max-width: 640px) {
		width: auto;
	}
}

.rightSection {
	@extend %flex-column;
	width: 50%;
	@media (min-width: 0) and (max-width: 710px) {
		width: auto;
	}
}

.verticalLine {
	border-right: solid 1px silver;
}

.formLabel {
  @extend %font-std;
  @media (min-width: 0px) and (max-width: 640px) {
		font-size: 16!important;
	}
}

.elementContainer {
	@extend %font-std;
	@extend %font-std;
  margin: 10px 10px 14px 0;
  @media (min-width: 0px) and (max-width: 640px) {
		font-size: 16px!important;
	}
}

.txtFld {
	@extend %font-std;
	width: 100%;
	border: none;
  border-bottom: solid 3px #707070;
  &:focus{
    outline:none
  }
}

.txtArea {
	@extend %font-std;
	width: 100%;
	resize: none;
	height: 85px;
  border: solid 2px #707070;
  @media (min-width: 0px) and (max-width: 640px) {
		font-size: 16px!important;
	}
}

.grpCntnr {
  margin: 0px 0px 160px 20px;
  @media (min-width: 0px) and (max-width: 640px) {
	  margin: 0px 0px 29px 0px;
	}
}

.grpItem {
	@extend %font-18;
  margin: 18px 10px 18px 10px;
  padding: 3px 13px;
  cursor:pointer;
  border-left: solid 5px transparent;
  @media (min-width: 0px) and (max-width: 640px) {
		font-size: 16px!important;
	}
}

.grpHdr {
	@extend %font-18-b;
}

.saveBtnPanel {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-self: flex-end;
	margin:35px 13px 5px 0;
}

  .grpSelected {
    background: #efefef;
    // margin: 18px 10px 18px 10px;
    // padding: 3px 13px;
    box-sizing: border-box;
    border-left: solid 5px #0457bc;
  }

.topics {
	margin-bottom:12px;
	@extend %flex-row
}

.left {
	width:50%;
	margin-left:43px
}

.right {
	width:50%
}

.saveBtnPanel {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-self: flex-end;
}

.buttonText {
    width: 168px;
    height: 60px;
    color: $white;
    @extend %font-18-b;
    @media (min-width: 0) and (max-width: 640px) {
      width: 126px!important;
      height: 50px!important;
      font-family: Bogle, sans-serif;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap
    }
  }

  .subTitle {
	  font-weight: bold;
	  margin-top:10px 
  }

  .para {
	@extend %font-18;
	  margin:9px 0 9px 0
  }

  .paraItalics {
	  @extend .para;
	  font-style: italic
  }

  .info {
	margin:5px 0 10px 0;
	@extend %font-18
  }

.uList li {
	list-style: none;
	line-height: 17px;
	&:before{
		font-size: 47px;
		content: '•';
		position: relative;
		top: 7px;
		padding-right: 4px;
	}
}

.greenIcon {
	color:green;
	align-self: center;
	margin-right:8px
}

.dateField {
	border: none;
    border-bottom: solid 2px black;
    font-size: 18px;
	font-family: Bogle, sans-serif;
	width:200px;

}

.datePanel {
	margin-top:20px
}

.cbLabel {
    @extend %font-std;
    @media (min-width: 0px) and (max-width: 640px) {
      font-size: 16px rem!important;
    }
  }

.scorePanel {
	@extend %flex-row;
	align-items:center;
}

.cbCntnr {
	padding:9px 0 20px 0
}

.scoreTxtBx {
	width:137px;
	height:27px;
	margin:0 20px 0 10px;
	&:focus{
		outline:none
	}
}

.warningContainer {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #e0b607;
}

.warningIcon {
	font-size: 20px;
	margin-right: 5px;
}

.warningMessage {
	font-family: Bogle;	
	font-size: 16px;
}