#pdf-content {
  img {
    pointer-events: none;
  }
}

.assessment-file-dialog {
  display: flex;
  background-color: white;
  flex-direction: column;
}
.pdf-container{
  overflow-x: auto;
  margin-bottom: 65px;
  margin-top: 95px
}

.pdf-container-mobile{
  position: absolute;
  top:190px;
  left:0%;
  right:0%;
  padding-bottom: 16%;
}
.file-header-content {
  z-index: 900;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4em;
  padding: 1em;
}

.stickyheader {
  position: fixed;
  top: 0;
  width:100%
}
@media (min-width: 0px) and (max-width: 360px) {
  .file-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 6em;
    padding: 1em;
  }

}
.close-aem-icon {
  font-size: 30px;
}
.paginator {
  align-self: center;
  justify-self: center;
}
.loading {
  position: relative;
}
.close-button {
  text-align: center;
  font-size: large;
  font-weight: bold;
  background: transparent;
}
.fab-button {
  background-color: transparent;
  position: fixed;
  bottom: 28%;
  right: 3%;
  z-index: 1;
}
.fab2-button {
  background-color: transparent;
  position: fixed;
  bottom: 28%;
  right: 3%;
  z-index: 1;
}
.fab3-button {
  background-color: transparent;
  position: fixed;
  bottom: 48%;
  right: 3%;
  z-index: 1;
  .MuiFab-root {
    background-color: var(--yellow-500);
    color: var(--white);
    &:hover {
      background-color: var(--yellow-500);
      color: var(--white);
    }
  }
}
.fab4-button {
  background-color: transparent;
  position: fixed;
  bottom: 58%;
  right: 3%;
  z-index: 1;
  .MuiFab-root {
    background-color: red;
    color: var(--white);
    &:hover {
      background-color: var(--red-500);
      color: var(--white);
    }
  }
  & > * {
    pointer-events: none;
  }
}
.notes-Drawer {
  padding: 20px;
}
.edit-icon {
  size: 20px;
}
.no-file-display-data {
  justify-content: center;
  padding-top: 2em;
  font-weight: 700;
  color: var(--gray-600);
  display: flex;
}
#pf5 {
  margin: 13px auto !important;
}
.pf {
  margin: 13px auto !important;
  position: relative !important;
  right: 0 !important;
  left: 0 !important;
}

.annotator-h1 {
  // color: white;
  background: #FFFF99;
  cursor: pointer;
  user-select: none;
  margin: -1px;
  // z-index: 1;
}

.annotator-h2 {
  // color: white;
  background: #FFCCFF;
  cursor: pointer;
  margin: -1px;
  user-select: none;
  // z-index: 1;
}

.annotator-h3 {
  // color: white;
  background: #CCFFCC;
  cursor: pointer;
  user-select: none;
  margin: -1px;
  // z-index: 1;
}


.annotator-h4 {
  // color: black;
  background: rgba(255, 255, 10, 0.3);
  margin: -1px;
  user-select: none;
  cursor: pointer;
  // z-index: 1;
}

.close-icon {
  font-size: 80%;
  position: sticky !important;
}

.annotator-cross-h1 {
  cursor: pointer;
  position: absolute !important;
  color: rgb(0,112,192);
  background: #FFFF99;
  margin:0 -2px;
  z-index: 1;
}

.annotator-cross-h2 {
  cursor: pointer;
  position: absolute !important;
  color: rgb(0,112,192);
  background: #FFCCFF;
  margin:0 -2px;
  z-index: 1;
}

.annotator-cross-h3 {
  cursor: pointer;
  position: absolute !important;
  color: rgb(0,112,192);
  background: #CCFFCC;
  z-index: 1;
  margin:0 -2px;
}

.annotator-cross-h4 {
  cursor: pointer;
  position: absolute !important;
  color: rgb(0,112,192);
  background: rgba(255, 255, 10, 0.3);
  z-index: 1;
}

/* .t span {
  position: unset;
} */


.radio-button {
  background: none;
  border: none;
}

.radioAvatar {
  cursor: pointer;
  border: solid 1px #ffffff;
  background: none;
  height: 24px;
  width: 24px;
  margin-right: 15px;
}

.radioAvatarHide {
  cursor: pointer;
  background: none;
  height: 24px;
  width: 24px;
  margin-right: 15px;
}

.firstRadio {
  color: #ff00b1 !important;
  cursor: pointer;
}

.secondRadio {
  color: #33ff91 !important;
  cursor: pointer;
}

.thirdRadio {
  color: #f0b9a5 !important;
  cursor: pointer;
}

.fab3-button {
  background-color: transparent;
  position: fixed;
  bottom: 38%;
  right: 3%;
  z-index: 1;
  color: var(--white);
  &:hover {
    background-color: var(--yellow-500);
    color: var(--white);
  }
}

.fab4-button {
  background-color: transparent;
  position: fixed;
  bottom: 38%;
  right: 3%;
  z-index: 1;
  color: var(--white);
  &:hover {
    background-color: var(--red-500);
    color: var(--white);
  }
  & > * {
    pointer-events: none;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
      margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
   @media (min-width: 0px) and (max-width: 414px){
     width:106%;
     padding-top: 0px
   }

}



h4,	
.h4,	
h5,	
.h5,	
h6,	
.h6,
h1,	
.h1,	
h2,	
.h2,	
h3,	
.h3{
  margin: 0px !important
}

.panelcontainer {
  width: 100%;
  height: 33px;
  background: #041e42;
  box-shadow: 0 5px 15px 0 rgba(15, 15, 15, 0.48);
  z-index: 99;
  position: fixed;
  top: 70px;
  color:#ffffff;
  text-align :center;
}

.panelcontainerMobile{
  width: 100%;
  height: 80px;
  background: #041e42;
  box-shadow: 0 5px 15px 0 rgba(15, 15, 15, 0.48);
  z-index: 99;
  position: fixed;
  top: 6%;
  text-align :center;
  color:#ffffff;
  font-size: 2.3rem;
}

.contentLoading{
  position: relative;
  top: 5px;
}

.pdfContainerMargin{
  // top:0 !important
}

.containerHeight{
  height:4rem !important;
  font-size:2rem !important
} 

.contentLoadingMobile{
  position: relative;
  top: 9px;
}

.rowMenuWidth {
  width: 180px;
  margin-top: 45px;
}